import { default as _91_91code_93_938qr9Z3Oz36Meta } from "/tmp/build/pages/[[code]].vue?macro=true";
import { default as index5jMMSNDAQQMeta } from "/tmp/build/pages/auth/login/index.vue?macro=true";
import { default as otpiV0PsPLVBmMeta } from "/tmp/build/pages/auth/login/otp.vue?macro=true";
import { default as logoutl0xjdfcOZeMeta } from "/tmp/build/pages/auth/logout.vue?macro=true";
import { default as entrepriseliYpAhKFJ7Meta } from "/tmp/build/pages/auth/register/entreprise.vue?macro=true";
import { default as indexxakDFbowWIMeta } from "/tmp/build/pages/auth/register/index.vue?macro=true";
import { default as personneoty8cEU8hJMeta } from "/tmp/build/pages/auth/register/personne.vue?macro=true";
import { default as _91code_93V0B3164jXnMeta } from "/tmp/build/pages/cart/checkout/[code].vue?macro=true";
import { default as indexgcXkbEYPXyMeta } from "/tmp/build/pages/cart/index.vue?macro=true";
import { default as indexBOk74HfNsOMeta } from "/tmp/build/pages/certificates/index.vue?macro=true";
import { default as indexL5JkSD7583Meta } from "/tmp/build/pages/document-library/index.vue?macro=true";
import { default as indexTf9kDsXUzPMeta } from "/tmp/build/pages/file-status/index.vue?macro=true";
import { default as helloworldmYOYz2Y8rUMeta } from "/tmp/build/pages/helloworld.vue?macro=true";
import { default as indexfUunabXtq8Meta } from "/tmp/build/pages/inbox/index.vue?macro=true";
import { default as index3K4prWRhu7Meta } from "/tmp/build/pages/membres/index.vue?macro=true";
import { default as _91id_93ruFBnYAsVuMeta } from "/tmp/build/pages/my-cars-copy/[id].vue?macro=true";
import { default as _91id_93wzsVXrhMAPMeta } from "/tmp/build/pages/my-cars/[id].vue?macro=true";
import { default as index0RL0p6XRaBMeta } from "/tmp/build/pages/my-cars/index.vue?macro=true";
import { default as profile0lwrP9NMTTMeta } from "/tmp/build/pages/profile.vue?macro=true";
import { default as indexUcgBM34xbSMeta } from "/tmp/build/pages/purchases/index.vue?macro=true";
import { default as _91id_93dR4jGjw7eDMeta } from "/tmp/build/pages/services/depot-de-titre/[id].vue?macro=true";
import { default as _91id_9334g00URejEMeta } from "/tmp/build/pages/services/depot-de-titre/edit/[id].vue?macro=true";
import { default as _91id_93IxC70ILKAkMeta } from "/tmp/build/pages/services/immatriculation-number-prestige/[id].vue?macro=true";
import { default as _91id_93cp3XsKYlC9Meta } from "/tmp/build/pages/services/immatriculation-number-prestige/edit/[id].vue?macro=true";
import { default as _91id_93owcfD2EcPkMeta } from "/tmp/build/pages/services/immatriculation-number/[id].vue?macro=true";
import { default as _91id_93x8EtcXHFBlMeta } from "/tmp/build/pages/services/immatriculation-number/edit/[id].vue?macro=true";
import { default as _91id_93Je2TroQNQqMeta } from "/tmp/build/pages/services/immatriculation-prestige-label/[id].vue?macro=true";
import { default as _91id_93zCa4kzabKxMeta } from "/tmp/build/pages/services/immatriculation-prestige-label/edit/[id].vue?macro=true";
import { default as _91id_93mVj5X0tUTqMeta } from "/tmp/build/pages/services/immatriculation-prestige-numero-label/[id].vue?macro=true";
import { default as _91id_93nPyYMGRztbMeta } from "/tmp/build/pages/services/immatriculation-prestige-numero-label/edit/[id].vue?macro=true";
import { default as _91id_93jUujnOmufHMeta } from "/tmp/build/pages/services/mutation/[id].vue?macro=true";
import { default as _91id_93k6U408d0T6Meta } from "/tmp/build/pages/services/mutation/edit/[id].vue?macro=true";
import { default as _91id_93kAismosUvQMeta } from "/tmp/build/pages/services/reimmatriculation/[id].vue?macro=true";
import { default as _91id_93uqgPCxUA3KMeta } from "/tmp/build/pages/services/reimmatriculation/edit/[id].vue?macro=true";
import { default as _91id_93tM87kmFTuuMeta } from "/tmp/build/pages/services/reprise-de-titre/[id].vue?macro=true";
import { default as _91id_93rrlc6CbkmFMeta } from "/tmp/build/pages/services/reprise-de-titre/edit/[id].vue?macro=true";
import { default as _91id_936THJ7SAhR3Meta } from "/tmp/build/pages/services/sales-declaration/[id].vue?macro=true";
import { default as _91id_93u4RiRakEA2Meta } from "/tmp/build/pages/services/sales-declaration/edit/[id].vue?macro=true";
import { default as _91id_93YbtWFplA9nMeta } from "/tmp/build/pages/services/vehicule-transformation/[id].vue?macro=true";
import { default as _91id_93Y7RNM5Yk3zMeta } from "/tmp/build/pages/services/vehicule-transformation/edit/[id].vue?macro=true";
import { default as facturesiHCSmjKuXjMeta } from "/tmp/build/pages/transactions/factures.vue?macro=true";
import { default as index0hUEG8yUYyMeta } from "/tmp/build/pages/transactions/index.vue?macro=true";
import { default as paiements1TKgosocEjMeta } from "/tmp/build/pages/transactions/paiements.vue?macro=true";
export default [
  {
    name: _91_91code_93_938qr9Z3Oz36Meta?.name ?? "code",
    path: _91_91code_93_938qr9Z3Oz36Meta?.path ?? "/:code?",
    meta: _91_91code_93_938qr9Z3Oz36Meta || {},
    alias: _91_91code_93_938qr9Z3Oz36Meta?.alias || [],
    redirect: _91_91code_93_938qr9Z3Oz36Meta?.redirect,
    component: () => import("/tmp/build/pages/[[code]].vue").then(m => m.default || m)
  },
  {
    name: index5jMMSNDAQQMeta?.name ?? "auth-login",
    path: index5jMMSNDAQQMeta?.path ?? "/auth/login",
    meta: index5jMMSNDAQQMeta || {},
    alias: index5jMMSNDAQQMeta?.alias || [],
    redirect: index5jMMSNDAQQMeta?.redirect,
    component: () => import("/tmp/build/pages/auth/login/index.vue").then(m => m.default || m)
  },
  {
    name: otpiV0PsPLVBmMeta?.name ?? "auth-login-otp",
    path: otpiV0PsPLVBmMeta?.path ?? "/auth/login/otp",
    meta: otpiV0PsPLVBmMeta || {},
    alias: otpiV0PsPLVBmMeta?.alias || [],
    redirect: otpiV0PsPLVBmMeta?.redirect,
    component: () => import("/tmp/build/pages/auth/login/otp.vue").then(m => m.default || m)
  },
  {
    name: logoutl0xjdfcOZeMeta?.name ?? "auth-logout",
    path: logoutl0xjdfcOZeMeta?.path ?? "/auth/logout",
    meta: logoutl0xjdfcOZeMeta || {},
    alias: logoutl0xjdfcOZeMeta?.alias || [],
    redirect: logoutl0xjdfcOZeMeta?.redirect,
    component: () => import("/tmp/build/pages/auth/logout.vue").then(m => m.default || m)
  },
  {
    name: entrepriseliYpAhKFJ7Meta?.name ?? "auth-register-entreprise",
    path: entrepriseliYpAhKFJ7Meta?.path ?? "/auth/register/entreprise",
    meta: entrepriseliYpAhKFJ7Meta || {},
    alias: entrepriseliYpAhKFJ7Meta?.alias || [],
    redirect: entrepriseliYpAhKFJ7Meta?.redirect,
    component: () => import("/tmp/build/pages/auth/register/entreprise.vue").then(m => m.default || m)
  },
  {
    name: indexxakDFbowWIMeta?.name ?? "auth-register",
    path: indexxakDFbowWIMeta?.path ?? "/auth/register",
    meta: indexxakDFbowWIMeta || {},
    alias: indexxakDFbowWIMeta?.alias || [],
    redirect: indexxakDFbowWIMeta?.redirect,
    component: () => import("/tmp/build/pages/auth/register/index.vue").then(m => m.default || m)
  },
  {
    name: personneoty8cEU8hJMeta?.name ?? "auth-register-personne",
    path: personneoty8cEU8hJMeta?.path ?? "/auth/register/personne",
    meta: personneoty8cEU8hJMeta || {},
    alias: personneoty8cEU8hJMeta?.alias || [],
    redirect: personneoty8cEU8hJMeta?.redirect,
    component: () => import("/tmp/build/pages/auth/register/personne.vue").then(m => m.default || m)
  },
  {
    name: _91code_93V0B3164jXnMeta?.name ?? "cart-checkout-code",
    path: _91code_93V0B3164jXnMeta?.path ?? "/cart/checkout/:code()",
    meta: _91code_93V0B3164jXnMeta || {},
    alias: _91code_93V0B3164jXnMeta?.alias || [],
    redirect: _91code_93V0B3164jXnMeta?.redirect,
    component: () => import("/tmp/build/pages/cart/checkout/[code].vue").then(m => m.default || m)
  },
  {
    name: indexgcXkbEYPXyMeta?.name ?? "cart",
    path: indexgcXkbEYPXyMeta?.path ?? "/cart",
    meta: indexgcXkbEYPXyMeta || {},
    alias: indexgcXkbEYPXyMeta?.alias || [],
    redirect: indexgcXkbEYPXyMeta?.redirect,
    component: () => import("/tmp/build/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: indexBOk74HfNsOMeta?.name ?? "certificates",
    path: indexBOk74HfNsOMeta?.path ?? "/certificates",
    meta: indexBOk74HfNsOMeta || {},
    alias: indexBOk74HfNsOMeta?.alias || [],
    redirect: indexBOk74HfNsOMeta?.redirect,
    component: () => import("/tmp/build/pages/certificates/index.vue").then(m => m.default || m)
  },
  {
    name: indexL5JkSD7583Meta?.name ?? "document-library",
    path: indexL5JkSD7583Meta?.path ?? "/document-library",
    meta: indexL5JkSD7583Meta || {},
    alias: indexL5JkSD7583Meta?.alias || [],
    redirect: indexL5JkSD7583Meta?.redirect,
    component: () => import("/tmp/build/pages/document-library/index.vue").then(m => m.default || m)
  },
  {
    name: indexTf9kDsXUzPMeta?.name ?? "file-status",
    path: indexTf9kDsXUzPMeta?.path ?? "/file-status",
    meta: indexTf9kDsXUzPMeta || {},
    alias: indexTf9kDsXUzPMeta?.alias || [],
    redirect: indexTf9kDsXUzPMeta?.redirect,
    component: () => import("/tmp/build/pages/file-status/index.vue").then(m => m.default || m)
  },
  {
    name: helloworldmYOYz2Y8rUMeta?.name ?? "helloworld",
    path: helloworldmYOYz2Y8rUMeta?.path ?? "/helloworld",
    meta: helloworldmYOYz2Y8rUMeta || {},
    alias: helloworldmYOYz2Y8rUMeta?.alias || [],
    redirect: helloworldmYOYz2Y8rUMeta?.redirect,
    component: () => import("/tmp/build/pages/helloworld.vue").then(m => m.default || m)
  },
  {
    name: indexfUunabXtq8Meta?.name ?? "inbox",
    path: indexfUunabXtq8Meta?.path ?? "/inbox",
    meta: indexfUunabXtq8Meta || {},
    alias: indexfUunabXtq8Meta?.alias || [],
    redirect: indexfUunabXtq8Meta?.redirect,
    component: () => import("/tmp/build/pages/inbox/index.vue").then(m => m.default || m)
  },
  {
    name: index3K4prWRhu7Meta?.name ?? "membres",
    path: index3K4prWRhu7Meta?.path ?? "/membres",
    meta: index3K4prWRhu7Meta || {},
    alias: index3K4prWRhu7Meta?.alias || [],
    redirect: index3K4prWRhu7Meta?.redirect,
    component: () => import("/tmp/build/pages/membres/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93ruFBnYAsVuMeta?.name ?? "my-cars-copy-id",
    path: _91id_93ruFBnYAsVuMeta?.path ?? "/my-cars-copy/:id()",
    meta: _91id_93ruFBnYAsVuMeta || {},
    alias: _91id_93ruFBnYAsVuMeta?.alias || [],
    redirect: _91id_93ruFBnYAsVuMeta?.redirect,
    component: () => import("/tmp/build/pages/my-cars-copy/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93wzsVXrhMAPMeta?.name ?? "my-cars-id",
    path: _91id_93wzsVXrhMAPMeta?.path ?? "/my-cars/:id()",
    meta: _91id_93wzsVXrhMAPMeta || {},
    alias: _91id_93wzsVXrhMAPMeta?.alias || [],
    redirect: _91id_93wzsVXrhMAPMeta?.redirect,
    component: () => import("/tmp/build/pages/my-cars/[id].vue").then(m => m.default || m)
  },
  {
    name: index0RL0p6XRaBMeta?.name ?? "my-cars",
    path: index0RL0p6XRaBMeta?.path ?? "/my-cars",
    meta: index0RL0p6XRaBMeta || {},
    alias: index0RL0p6XRaBMeta?.alias || [],
    redirect: index0RL0p6XRaBMeta?.redirect,
    component: () => import("/tmp/build/pages/my-cars/index.vue").then(m => m.default || m)
  },
  {
    name: profile0lwrP9NMTTMeta?.name ?? "profile",
    path: profile0lwrP9NMTTMeta?.path ?? "/profile",
    meta: profile0lwrP9NMTTMeta || {},
    alias: profile0lwrP9NMTTMeta?.alias || [],
    redirect: profile0lwrP9NMTTMeta?.redirect,
    component: () => import("/tmp/build/pages/profile.vue").then(m => m.default || m)
  },
  {
    name: indexUcgBM34xbSMeta?.name ?? "purchases",
    path: indexUcgBM34xbSMeta?.path ?? "/purchases",
    meta: indexUcgBM34xbSMeta || {},
    alias: indexUcgBM34xbSMeta?.alias || [],
    redirect: indexUcgBM34xbSMeta?.redirect,
    component: () => import("/tmp/build/pages/purchases/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93dR4jGjw7eDMeta?.name ?? "services-depot-de-titre-id",
    path: _91id_93dR4jGjw7eDMeta?.path ?? "/services/depot-de-titre/:id()",
    meta: _91id_93dR4jGjw7eDMeta || {},
    alias: _91id_93dR4jGjw7eDMeta?.alias || [],
    redirect: _91id_93dR4jGjw7eDMeta?.redirect,
    component: () => import("/tmp/build/pages/services/depot-de-titre/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_9334g00URejEMeta?.name ?? "services-depot-de-titre-edit-id",
    path: _91id_9334g00URejEMeta?.path ?? "/services/depot-de-titre/edit/:id()",
    meta: _91id_9334g00URejEMeta || {},
    alias: _91id_9334g00URejEMeta?.alias || [],
    redirect: _91id_9334g00URejEMeta?.redirect,
    component: () => import("/tmp/build/pages/services/depot-de-titre/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93IxC70ILKAkMeta?.name ?? "services-immatriculation-number-prestige-id",
    path: _91id_93IxC70ILKAkMeta?.path ?? "/services/immatriculation-number-prestige/:id()",
    meta: _91id_93IxC70ILKAkMeta || {},
    alias: _91id_93IxC70ILKAkMeta?.alias || [],
    redirect: _91id_93IxC70ILKAkMeta?.redirect,
    component: () => import("/tmp/build/pages/services/immatriculation-number-prestige/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93cp3XsKYlC9Meta?.name ?? "services-immatriculation-number-prestige-edit-id",
    path: _91id_93cp3XsKYlC9Meta?.path ?? "/services/immatriculation-number-prestige/edit/:id()",
    meta: _91id_93cp3XsKYlC9Meta || {},
    alias: _91id_93cp3XsKYlC9Meta?.alias || [],
    redirect: _91id_93cp3XsKYlC9Meta?.redirect,
    component: () => import("/tmp/build/pages/services/immatriculation-number-prestige/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93owcfD2EcPkMeta?.name ?? "services-immatriculation-number-id",
    path: _91id_93owcfD2EcPkMeta?.path ?? "/services/immatriculation-number/:id()",
    meta: _91id_93owcfD2EcPkMeta || {},
    alias: _91id_93owcfD2EcPkMeta?.alias || [],
    redirect: _91id_93owcfD2EcPkMeta?.redirect,
    component: () => import("/tmp/build/pages/services/immatriculation-number/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93x8EtcXHFBlMeta?.name ?? "services-immatriculation-number-edit-id",
    path: _91id_93x8EtcXHFBlMeta?.path ?? "/services/immatriculation-number/edit/:id()",
    meta: _91id_93x8EtcXHFBlMeta || {},
    alias: _91id_93x8EtcXHFBlMeta?.alias || [],
    redirect: _91id_93x8EtcXHFBlMeta?.redirect,
    component: () => import("/tmp/build/pages/services/immatriculation-number/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Je2TroQNQqMeta?.name ?? "services-immatriculation-prestige-label-id",
    path: _91id_93Je2TroQNQqMeta?.path ?? "/services/immatriculation-prestige-label/:id()",
    meta: _91id_93Je2TroQNQqMeta || {},
    alias: _91id_93Je2TroQNQqMeta?.alias || [],
    redirect: _91id_93Je2TroQNQqMeta?.redirect,
    component: () => import("/tmp/build/pages/services/immatriculation-prestige-label/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93zCa4kzabKxMeta?.name ?? "services-immatriculation-prestige-label-edit-id",
    path: _91id_93zCa4kzabKxMeta?.path ?? "/services/immatriculation-prestige-label/edit/:id()",
    meta: _91id_93zCa4kzabKxMeta || {},
    alias: _91id_93zCa4kzabKxMeta?.alias || [],
    redirect: _91id_93zCa4kzabKxMeta?.redirect,
    component: () => import("/tmp/build/pages/services/immatriculation-prestige-label/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93mVj5X0tUTqMeta?.name ?? "services-immatriculation-prestige-numero-label-id",
    path: _91id_93mVj5X0tUTqMeta?.path ?? "/services/immatriculation-prestige-numero-label/:id()",
    meta: _91id_93mVj5X0tUTqMeta || {},
    alias: _91id_93mVj5X0tUTqMeta?.alias || [],
    redirect: _91id_93mVj5X0tUTqMeta?.redirect,
    component: () => import("/tmp/build/pages/services/immatriculation-prestige-numero-label/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93nPyYMGRztbMeta?.name ?? "services-immatriculation-prestige-numero-label-edit-id",
    path: _91id_93nPyYMGRztbMeta?.path ?? "/services/immatriculation-prestige-numero-label/edit/:id()",
    meta: _91id_93nPyYMGRztbMeta || {},
    alias: _91id_93nPyYMGRztbMeta?.alias || [],
    redirect: _91id_93nPyYMGRztbMeta?.redirect,
    component: () => import("/tmp/build/pages/services/immatriculation-prestige-numero-label/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93jUujnOmufHMeta?.name ?? "services-mutation-id",
    path: _91id_93jUujnOmufHMeta?.path ?? "/services/mutation/:id()",
    meta: _91id_93jUujnOmufHMeta || {},
    alias: _91id_93jUujnOmufHMeta?.alias || [],
    redirect: _91id_93jUujnOmufHMeta?.redirect,
    component: () => import("/tmp/build/pages/services/mutation/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93k6U408d0T6Meta?.name ?? "services-mutation-edit-id",
    path: _91id_93k6U408d0T6Meta?.path ?? "/services/mutation/edit/:id()",
    meta: _91id_93k6U408d0T6Meta || {},
    alias: _91id_93k6U408d0T6Meta?.alias || [],
    redirect: _91id_93k6U408d0T6Meta?.redirect,
    component: () => import("/tmp/build/pages/services/mutation/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93kAismosUvQMeta?.name ?? "services-reimmatriculation-id",
    path: _91id_93kAismosUvQMeta?.path ?? "/services/reimmatriculation/:id()",
    meta: _91id_93kAismosUvQMeta || {},
    alias: _91id_93kAismosUvQMeta?.alias || [],
    redirect: _91id_93kAismosUvQMeta?.redirect,
    component: () => import("/tmp/build/pages/services/reimmatriculation/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93uqgPCxUA3KMeta?.name ?? "services-reimmatriculation-edit-id",
    path: _91id_93uqgPCxUA3KMeta?.path ?? "/services/reimmatriculation/edit/:id()",
    meta: _91id_93uqgPCxUA3KMeta || {},
    alias: _91id_93uqgPCxUA3KMeta?.alias || [],
    redirect: _91id_93uqgPCxUA3KMeta?.redirect,
    component: () => import("/tmp/build/pages/services/reimmatriculation/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93tM87kmFTuuMeta?.name ?? "services-reprise-de-titre-id",
    path: _91id_93tM87kmFTuuMeta?.path ?? "/services/reprise-de-titre/:id()",
    meta: _91id_93tM87kmFTuuMeta || {},
    alias: _91id_93tM87kmFTuuMeta?.alias || [],
    redirect: _91id_93tM87kmFTuuMeta?.redirect,
    component: () => import("/tmp/build/pages/services/reprise-de-titre/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93rrlc6CbkmFMeta?.name ?? "services-reprise-de-titre-edit-id",
    path: _91id_93rrlc6CbkmFMeta?.path ?? "/services/reprise-de-titre/edit/:id()",
    meta: _91id_93rrlc6CbkmFMeta || {},
    alias: _91id_93rrlc6CbkmFMeta?.alias || [],
    redirect: _91id_93rrlc6CbkmFMeta?.redirect,
    component: () => import("/tmp/build/pages/services/reprise-de-titre/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_936THJ7SAhR3Meta?.name ?? "services-sales-declaration-id",
    path: _91id_936THJ7SAhR3Meta?.path ?? "/services/sales-declaration/:id()",
    meta: _91id_936THJ7SAhR3Meta || {},
    alias: _91id_936THJ7SAhR3Meta?.alias || [],
    redirect: _91id_936THJ7SAhR3Meta?.redirect,
    component: () => import("/tmp/build/pages/services/sales-declaration/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93u4RiRakEA2Meta?.name ?? "services-sales-declaration-edit-id",
    path: _91id_93u4RiRakEA2Meta?.path ?? "/services/sales-declaration/edit/:id()",
    meta: _91id_93u4RiRakEA2Meta || {},
    alias: _91id_93u4RiRakEA2Meta?.alias || [],
    redirect: _91id_93u4RiRakEA2Meta?.redirect,
    component: () => import("/tmp/build/pages/services/sales-declaration/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93YbtWFplA9nMeta?.name ?? "services-vehicule-transformation-id",
    path: _91id_93YbtWFplA9nMeta?.path ?? "/services/vehicule-transformation/:id()",
    meta: _91id_93YbtWFplA9nMeta || {},
    alias: _91id_93YbtWFplA9nMeta?.alias || [],
    redirect: _91id_93YbtWFplA9nMeta?.redirect,
    component: () => import("/tmp/build/pages/services/vehicule-transformation/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Y7RNM5Yk3zMeta?.name ?? "services-vehicule-transformation-edit-id",
    path: _91id_93Y7RNM5Yk3zMeta?.path ?? "/services/vehicule-transformation/edit/:id()",
    meta: _91id_93Y7RNM5Yk3zMeta || {},
    alias: _91id_93Y7RNM5Yk3zMeta?.alias || [],
    redirect: _91id_93Y7RNM5Yk3zMeta?.redirect,
    component: () => import("/tmp/build/pages/services/vehicule-transformation/edit/[id].vue").then(m => m.default || m)
  },
  {
    name: facturesiHCSmjKuXjMeta?.name ?? "transactions-factures",
    path: facturesiHCSmjKuXjMeta?.path ?? "/transactions/factures",
    meta: facturesiHCSmjKuXjMeta || {},
    alias: facturesiHCSmjKuXjMeta?.alias || [],
    redirect: facturesiHCSmjKuXjMeta?.redirect,
    component: () => import("/tmp/build/pages/transactions/factures.vue").then(m => m.default || m)
  },
  {
    name: index0hUEG8yUYyMeta?.name ?? "transactions",
    path: index0hUEG8yUYyMeta?.path ?? "/transactions",
    meta: index0hUEG8yUYyMeta || {},
    alias: index0hUEG8yUYyMeta?.alias || [],
    redirect: index0hUEG8yUYyMeta?.redirect,
    component: () => import("/tmp/build/pages/transactions/index.vue").then(m => m.default || m)
  },
  {
    name: paiements1TKgosocEjMeta?.name ?? "transactions-paiements",
    path: paiements1TKgosocEjMeta?.path ?? "/transactions/paiements",
    meta: paiements1TKgosocEjMeta || {},
    alias: paiements1TKgosocEjMeta?.alias || [],
    redirect: paiements1TKgosocEjMeta?.redirect,
    component: () => import("/tmp/build/pages/transactions/paiements.vue").then(m => m.default || m)
  }
]